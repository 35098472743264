<template>
	<div class="content">
		<vue-headful
			:title="pageTitle"
		/>
		
		<main>
			<div class="info">
				<h2>{{ $t('point_camera') }}</h2>
				<h3>{{ $t('point_camera_device') }}</h3>
			</div>
			
			<web-cam ref="webcam"
			         class="webcam"
			         :device-id="deviceId"
			         width="100%"
			         height="100%"
			         @started="onStarted"
			         @error="onError"
			         @cameras="onCameras"
			         @video-live="startRecognition"
			/>
			<lottie
				id="animation"
				:options="defaultOptions"
			/>
			
			<img v-if="debugging" class="preview" :src="img" alt="">
			<p v-if="debugging" class="preview-text">{{ $t('preview') }}</p>
			<p v-if="debugging" class="preview-text-response">
				{{ $t('response') }}: <span>{{ response }}</span>
			</p>
			<i class="camera-icon mm-icon mm-icon-flip-camera big" @click="changeCamera()"/>
		</main>
		
		<footer>
			<div class="footer-buttons">
				<button class="button-white" @click="goBack()">
					<i class="mm-icon mm-icon-close big"/>
				</button>
			</div>
		</footer>
	</div>
</template>

<script>
const {debug} = require("@/misc/debug");
import * as mutationTypes from "@/store/mutation-types";
import axios from "axios";
import * as https from "https";
import {mapActions, mapState} from "vuex";
const Lottie = () => import("vue-lottie");
import * as animationData from '@/assets/animations/camera.json';
import {WebCam} from "vue-web-cam";
import {setFullScreen} from "@/store/helpers";
import firebase from "firebase/app";

export default {
	name: "Recognition",
	
	components: {
		Lottie,
		WebCam
	},
	
	data() {
		return {
			encrypted: this.$route.params.idMuseum,
			idMuseum: "0",
			defaultOptions: {
				animationData: animationData.default,
				animationSpeed: 1
			},
			response: {},
			debugging: (process.env.VUE_APP_DEBUG_MODE == "true"),
			img: null,
			camera: null,
			cameraIndex: 0,
			deviceId: null,
			devices: [],
			modalNotification: null,
			showNotification: localStorage.getItem('recognition-notification-seen') !== "true",
		};
	},
	
	computed: {
		...mapState({
			museum: state => state.museum.museum,
			isLoading: state => state.app.isLoading,
			isNetworkOnline: state => state.app.isNetworkOnline
		}),
		
		pageTitle() {
			let locationName = this.$i18n.t('image_recognition');
			
			let museumName = (this.museum?.name)
				? this.museum.name
				: this.museum?.locale?.name
					? this.museum.locale.name
					: "MuseumMate";
			
			return locationName + ' | ' + museumName;
		},
		
		device() {
			return this.devices.find(n => n.deviceId === this.deviceId);
		}
	},
	
	watch: {
		camera: function(id) {
			this.deviceId = id;
		},
		devices: function() {
			if (this.devices.length) {
				if(this.devices[this.devices.length-1]) {
					this.camera = this.devices[this.devices.length-1].deviceId;
					this.deviceId = this.devices[this.devices.length-1].deviceId;
					this.cameraIndex = this.devices.length-1;
				}
			}
		}
	},
	
	created() {
		debug.log("[Recognition] Creating view");
		this.$store.commit('app/' + mutationTypes.SET_IS_LOADING, true);
		
		try {
			let decryption = (atob(this.encrypted));
			this.idMuseum = decryption.split('-')[0];
			
			this.loadMuseum(this.idMuseum)
				.then(() => {
					this.$store.commit('app/' + mutationTypes.SET_IS_LOADING, false);
					
					if(this.showNotification) {
						this.modalNotification = this.$swal({
							padding: '25px 20px 20px 20px',
							text: this.$i18n.t('recognition_offline_notification'),
							heightAuto: false,
							showConfirmButton: true,
							showCancelButton: true,
							reverseButtons: true,
							confirmButtonText: this.$i18n.t('yes'),
							cancelButtonText: this.$i18n.t('no'),
						});
						
						this.modalNotification.then((response) => {
							if (response.isConfirmed) {
								this.showNotification = false;
								localStorage.setItem('recognition-notification-seen', String(true));
							} else {
								localStorage.setItem('recognition-notification-seen', String(false));
								this.$router.go(-1);
							}
						});
					}
					
					if(firebase.app().options.measurementId) {
						debug.log("Logging page_view analytics");
						firebase.analytics().logEvent('page_view', {
							page_location: this.$router.currentRoute.fullPath,
							page_title: this.pageTitle
						});
					} else {
						debug.log("No measurementId found, no analytics sent");
					}
				});
		} catch(e) {
			if (process.env.VUE_APP_DEBUG_MODE == "true") console.error("Error deciphering: ", e);
			this.$store.commit('app/' + mutationTypes.SET_IS_LOADING, false);
		}
	},
	
	methods: {
		...mapActions('app', ['loadMuseum']),
		
		onStarted(stream) {
			debug.log("[onStarted] Camera started", stream);
		},
		
		onError(error) {
			debug.log("[onError] Error:", error);
		},
		
		onCameras(cameras) {
			this.devices = cameras;
			debug.log("[onCameras] Cameras detected:", cameras);
		},
		
		changeCamera() {
			let nextIndex = this.devices[this.cameraIndex+1] ? this.cameraIndex+1 : 0;
			debug.log("[changeCamera] Changed to camera " + nextIndex);
			this.cameraIndex = nextIndex;
			this.deviceId = this.devices[nextIndex].deviceId;
			this.camera = this.devices[nextIndex].deviceId;
		},
		
		startRecognition() {
			if(!this.showNotification && this.camera && this.deviceId) {
				let frame = this.$refs.webcam.capture();
				this.img = frame;
				
				if (frame.length > 10) {
					let data = new FormData(); // Data to be sent to the recognition service
					data.append("museumId", this.idMuseum);
					data.append("image", this.dataURLtoFile(frame));
					
					const agent = new https.Agent({
						rejectUnauthorized: false
					});
					
					let self = this;
					// Send image to back-end
					axios.post(process.env.VUE_APP_API_RECOGNITION_URL, data,
						{
							headers: {
								'Content-Type': 'multipart/form-data',
								'Authorization': process.env.VUE_APP_API_RECOGNITION_TOKEN
							}, httpsAgent: agent
						})
						
						.then((response) => {
							self.response = JSON.stringify(response.data);
							this.goToArtwork(response['data']['artworkId']);
						})
						
						.catch((error) => {
							self.response = error?.response?.data;
							debug.log("[startRecognition] Error:", error);
							setTimeout(this.startRecognition, parseInt(process.env.VUE_APP_API_TIME_MILLISECONDS_REQUEST));
						});
				} else {
					setTimeout(this.startRecognition, parseInt(process.env.VUE_APP_API_TIME_MILLISECONDS_REQUEST));
				}
			} else {
				setTimeout(this.startRecognition, parseInt(process.env.VUE_APP_API_TIME_MILLISECONDS_REQUEST));
			}
		},
		
		sendBlankImage() {
			let data = new FormData(); // Data to be sent to the recognition service
			data.append("museumId", this.idMuseum);
			data.append("image", this.dataURLtoFile('data:,'));
			this.img = 'data:,';
			
			const agent = new https.Agent({
				rejectUnauthorized: false
			});
			
			// Send image to back-end
			axios.post(process.env.VUE_APP_API_RECOGNITION_URL, data,
				{
					headers: {
						'Content-Type': 'multipart/form-data',
						'Authorization': process.env.VUE_APP_API_RECOGNITION_TOKEN
					}, httpsAgent: agent
				})
				
				.catch((error) => {
					debug.log("[startRecognition] Error:", error);
				});
		},
		
		dataURLtoFile(dataUrl) {
			let arr = dataUrl.split(','),
				mime = arr[0].match(/:(.*?);/)[1],
				bstr = atob(arr[1]),
				n = bstr.length,
				u8arr = new Uint8Array(n);
			
			while (n--) {
				u8arr[n] = bstr.charCodeAt(n);
			}
			
			return new File([u8arr], "frame.jpg", {type: mime});
		},
		
		goToArtwork(artworkId) {
			let artwork = this.museum.artworks.find(artwork => artwork.idArtwork === artworkId);
			
			if (artwork) {
				this.$router.push({
					name: 'Artwork',
					params: { idMuseum: this.encrypted, idArtwork: String(artwork.idArtwork) },
					query: { from: 'Recognition' }
				});
			} else {
				debug.log("[goToArtwork] Error: The recognized artwork does not belong to this museum");
				this.startRecognition();
			}
		},
		
		goBack() {
			setFullScreen();
			this.$router.go(-1);
		},
	},
};

</script>

<style lang="scss" scoped>
@import '../theme/colors.scss';

.content {
	background-color: black;
	width: 100%;
	height: 100%;
	overflow: hidden;
	
	main {
		position: relative;
		width: 100%;
		height: 100%;
		
		.info {
			padding: 30px 30px;
			position: fixed;
			color: white;
			text-align: center;
			font-weight: normal;
			
			h2 {
				font-size: 18px;
				margin-bottom: 10px;
			}
			
			h3 {
				font-size: 12px;
			}
		}
		
		.webcam {
			object-fit: cover;
		}
		
		.preview {
			position: absolute;
			bottom: 110px;
			left: 20px;
			max-width: 200px;
			max-height: 200px;
			border: 1px solid white;
			border-radius: 5px;
		}
		
		.preview-text {
			position: absolute;
			bottom: 115px;
			left: 20px;
			color: white;
		}
		
		.preview-text-response {
			position: absolute;
			bottom: 90px;
			left: 20px;
			right: 20px;
			color: white;
		}
		
		.camera-icon {
			position: absolute;
			bottom: 110px;
			right: 20px;
			color: white;
		}
		
		#animation {
			position: absolute;
			top: 0;
			left: 0;
			min-height: 100%;
			min-width: 100%;
			width: auto;
			height: auto;
		}
	}
	
	footer {
		position: fixed;
		bottom: 0;
		height: 90px;
		width: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
		
		.footer-buttons {
			width: 100%;
			min-height: 90px;
			display: flex;
			justify-content: center;
			position: absolute;
			top: 0;
			
			button {
				width: 100%;
				border: none;
				text-transform: uppercase;
				display: flex;
				justify-content: center;
				align-items: center;
				
				&.button-white {
					background-color: white;
					color: black;
				}
			}
		}
	}
}

</style>
